import React from "react"
import JenMsgVideo from "../video/jenmsg.mp4"
import Hero from "../components/hero"
import Layout from '../components/layout-default'
import teamsStyles from "../components/teams.module.scss"
import { Link } from "gatsby"

import TeamsBachata from "../images/rf-teams-bachata.jpeg"
import TeamsMen from "../images/rf-teams-men.jpeg"
import TeamsLadies from "../images/rf-teams-ladies.jpeg"
import TeamsKids from "../images/rf-teams-kids.jpeg"
import TeamsSalsa from "../images/rf-teams-salsa.jpeg"

import TeamImg from "../images/team.jpeg"
import JenMsg from "../static/jenmsg.png"
import promo from "../../src/static/auditions.mp4"
import "../styles/layout.scss"

const Team = props => (
    <div className={`${teamsStyles.column}  display--inline-block column is-one-third-desktop is-half-tablet is-three-quarters-mobile`}>
      <img src={props.src} alt={props.alt} />
      {(props.title ? <h4 style={({'marginBottom':'0.5em'})}>{props.title}</h4>: ``)}
      <p>{props.desc}</p>
      {(props.link ? <Link to={props.link}>{props.linkText}</Link> : `` )}
    </div>
)

const TeamsPage = () => (
  <Layout
    title="Latin Dance Teams | RF Dance"
    description="The OC's Homegrown latin dance teams." 
    pathname="teams"
    img={TeamImg}>
    <Hero 
      titleRed="RF"
      titleWhite="Teams"
      subtitle="The OC's homegrown latin dance teams"
      linkOut="https://www.youtube.com/watch?v=y1MxHMCmHpg"
      alt={true}
      promo={promo}
      img={JenMsg}
      altMsgDate="March 15, 2019"
      altMsgCallout="Attention Men &amp; Leads!"
      altMsg="Watch Jen Silvas and the ladies of RF Dance send a special message to all the men/leads out there!"
      altExpiry="04/01/2019"
      altPromo={JenMsgVideo}/>
      
      <section className={`${teamsStyles.content} content thirds`}>
        <div className={`${teamsStyles.contentBlock} content-block`}>
          <div className="container">
            <div className={`${teamsStyles.columns} columns display--flex-center`}>
              {/* <Team 
                src={TeamsNoPerf} 
                alt="Non-Performing Team" 
                desc="Accelerate your dancing skills with our non-performance Training Team."
                title="Training Team"
                link="/teams/training-team"
                linkText="Sign up for the Training Team"/> */}
              <Team 
                src={TeamsBachata} 
                alt="RF Bachata Team" 
                desc="With 4 levels of RF Bachata Teams featuring a blend of the hottest modern and sensual bachata choreography, RF has a place for you!"
                link="/teams/bachata"
                linkText="Learn more"/>
              <Team 
                src={TeamsSalsa} 
                alt="RF Salsa Team" 
                desc="The RF Salsa Team offers 2 different levels of On1 Rhythmic Fusion-style Salsa. RF Salsa brings the heat to the dance floor!"
                link="/teams/salsa"
                linkText="Learn more"/>
              <Team 
                src={TeamsMen} 
                alt="RF Men" 
                desc="The RF Men’s Team features a strong and expressive blend of modern Latin music and choreography."
                link="/teams/men"
                linkText="Learn more"/>
              <Team 
                src={TeamsLadies} 
                alt="RF Ladies" 
                desc="Sassy yet classy, the RF Ladies Team shows off feminine energy and sensuality through music and choreography that gives a Latin feel with a modern twist."
                link="/teams/ladies"
                linkText="Learn more"/>
              <Team 
                src={TeamsKids} 
                alt="RF Kids" 
                desc="Cultivating talent among the youngest members of our Latin dance community, the RF Kids Team brings the most adorable element to the Latin dance stage!"
                link="/teams/kids"
                linkText="Learn more"/>
              {/* <Team 
                src={TeamsJazz} 
                alt="RF Jazz" 
                desc="The RF Jazz Team brings a more traditional dance background paired with contemporary music and choreography that is sure to inspire dancer and spectator alike!"/>
               */}
            </div>
            
          </div>
        </div>

        <hr className="divider" />

        <div className="content-block text-center">
          <div className="container">
            <h2>Rhythmic Fusion’s Teams are the heart and soul of Orange County’s Latin Dance community.</h2>
            <p>Whether it's your first time joining a dance team, or if you're a seasoned veteran - it will be an exciting, challenging, and enjoyable season.  We aren't just your typical dance team, we are a family.  We invest in you, the team, and the experience.  Every year we try to strengthen our dance company by investing in each and every dancer's experience.</p>
          </div>
        </div>

      </section>
  </Layout>
)

export default TeamsPage